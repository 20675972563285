import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

function ContactPage() {
  const formName = 'contact'
  return (
    <Layout>
      <SEO title="Contact" />
      <main>
        <h1 className="text-xl lg:text-2xl text-center mb-4">Contact Us</h1>

        <form
          className="mx-auto md:w-1/2"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          method="post"
          name={formName}
        >
          <label className="block">
            <span className="font-medium text-sm">Name</span>
            <input className="form-input mt-1 block w-full" name="name" />
          </label>
          <label className="block mt-5">
            <span className="font-medium text-sm">Email</span>
            <input
              className="form-input mt-1 block w-full"
              name="email"
              type="email"
            />
          </label>
          <label className="block mt-5">
            <span className="font-medium text-sm">Phone Number</span>
            <input
              className="form-input mt-1 block w-full"
              name="telephone"
              type="tel"
            />
          </label>
          <label className="block mt-5">
            <span className="font-medium text-sm">How can we help you?</span>
            <textarea
              className="form-textarea mt-1 block w-full"
              name="message"
              rows="6"
            ></textarea>
          </label>
          <label
            aria-hidden="true"
            aria-label="spam protection. skip this field"
            className="hidden"
          >
            <span>Don&apos;t fill this out if you&apos;re human</span>
            <input name="bot-field" />
          </label>
          <div className="mt-10 text-right">
            <button className="btn btn-purple" type="submit">
              Send
            </button>
          </div>
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input name="form-name" type="hidden" value={formName} />
        </form>
      </main>
    </Layout>
  )
}

export default ContactPage
